const mixin = {
  data() {
    return {
      //HOST: "http://68.178.168.214:8888",
      //SECURITY: "http://68.178.168.214:8888",
      HOST: process.env.VUE_APP_BASE_URL,
      SECURITY: process.env.VUE_APP_BASE_URL,
      PROPERTY: process.env.VUE_APP_APPTITLE,
      OAUTH_CLIENT_ID: "smclient",
      OAUTH_CLIENT_SECRET: "smclient00ll",
      OAUTH_SCOPE: "openid",
      OAUTH_GRANT_TYPE: "password",
      proxy: {
        host: process.env.VUE_APP_PORT,
        port: process.env.VUE_APP_PORT,
      },
      // proxy: {
      //   host: "localhost",
      //   port: 8080,
      // },
      REPORT_AVAILABLE: "Active",
      REPORT_UNAVAILABLE: "Inactive",
      expandableHeaderBackground: "#1E88E5",
    };
  },
  created: function() {},
  methods: {},
};
export default mixin;
