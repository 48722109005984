import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"text":"","icon":""}},on),[_c(VIcon,{staticStyle:{"font-size":"50px"}},[_vm._v("mdi-account-circle")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{staticStyle:{"font-size":"50px"}},[_vm._v("mdi-account-circle")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.dynamic.user.username))])],1)],1)],1),_c(VDivider),_c(VList,[_c(VListItemGroup,{attrs:{"color":"primary"}},[_c(VListItem,{on:{"click":_vm.getLogOut}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-logout")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Signout")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }