import Vue from 'vue';
import App from './App.vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
Vue.config.productionTip = false;
import VueProgressBar from 'vue-progressbar';
const options = {
  color: '#009944',
  failedColor: '#e43912',
  thickness: '10px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
};
import client from './client';
Vue.prototype.$http = client;
Vue.use(VueProgressBar, options);
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
Vue.use(VueMoment, {
  moment,
});
import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);
import Notifications from 'vue-notification';
Vue.use(Notifications);
import mixin from './mixin';
Vue.mixin(mixin);
var infiniteScroll = require('vue-infinite-scroll');
Vue.use(infiniteScroll);
import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
var VueCookie = require('vue-cookie');
Vue.use(VueCookie);
new Vue({
  router,
  store,
  vuetify,
  render: function(h) {
    return h(App);
  },
}).$mount('#app');
