import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2979ff',
                secondary: '#03a9f4',
                accent: '#ff5722',
                error: '#f44336',
                warning: '#ff9800',
                info: '#3f51b5',
                success: '#4caf50'
            },
        },
    },


});
