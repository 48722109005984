import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VNavigationDrawer,{staticClass:"hidden-md-and-up",attrs:{"fixed":"","app":"","temporary":true},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,_vm._l((_vm.toolBarLinks),function(link){return _c(VListItem,{key:link.id,class:{active :link.id==_vm.dynamic.activeView},on:{"click":function($event){return _vm.route(link)}}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(_vm._s(link.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(link.title))])],1)],1)}),1)],1),_c(VAppBar,{staticClass:"bg-purple",attrs:{"absolute":"","color":"white","elevate-on-scroll":"","scroll-target":"#main-container"}},[_c(VAppBarNavIcon,{staticClass:"white",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VSpacer,{staticClass:"hidden-md-and-down",staticStyle:{"width":"300px"}}),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.static.AppTitle))]),_c(VSpacer),_c(VContainer,{staticStyle:{"padding":"0 50px","height":"100%"}},[_c(VRow,{staticStyle:{"height":"100%"},attrs:{"justify":"end"}},[_c(VToolbarItems,{staticClass:"hidden-sm-and-down toolbar-items",staticStyle:{"float":"right","height":"100%"}},_vm._l((_vm.toolBarLinks),function(link){return _c(VBtn,{key:link.id,staticClass:"toolBarLink",class:{active :link.id==_vm.dynamic.activeView},attrs:{"append":true,"text":""},on:{"click":function($event){return _vm.route(link)}}},[_c(VIcon,[_vm._v(_vm._s(link.icon))]),_vm._v(_vm._s(link.title))],1)}),1)],1)],1),_c(VSpacer,{staticClass:"hidden-md-and-down",staticStyle:{"width":"100px"}})],1),_c(VContent,{staticClass:"overflow-y-auto",staticStyle:{"height":"100vh","padding":"70px 0 20vh 0 !important"},attrs:{"id":"main-container"}},[_c('router-view',{staticStyle:{"overflow":"hidden"},attrs:{"name":"public"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }