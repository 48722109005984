<template>
    <div id="super-container">
        <v-content id="main-container" style=" height: 100vh;text-align: center">
                <h1 style="color: orangered;font-size: 80px;margin-top: 20vh">404</h1>
                <p style="color: #383838;font-size: 40px">Page not found</p>
                <v-btn flat color="error" @click="$router.go(-1)">Back</v-btn>
        </v-content>
    </div>
</template>
<script>
    import {mapState,mapMutations} from 'vuex'
    export default {
        data(){
            return {

            }
        },
        computed:{

        },
        methods:{

        }
    }
</script>
<style scoped>


</style>