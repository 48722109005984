<template>
    <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
    >
        <template v-slot:activator="{ on }">
            <v-btn text  v-on="on" icon>
                <v-icon style="font-size: 50px">mdi-account-circle</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-list>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon style="font-size: 50px">mdi-account-circle</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                       
                        <v-list-item-title>{{dynamic.user.username}}</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
                <v-list-item-group color="primary">
                    <v-list-item @click="getLogOut">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Signout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script>
    import {mapState,mapMutations} from 'vuex'
    export default {
        data(){
            return{
                fav: true,
                menu: false,
                message: false,
                hints: true,
            }
        },
        computed:{
            ...mapState([
                'static','dynamic'
            ]),
        },
        methods:{
            ...mapMutations([
                'SET_ACTIVE_VIEW','SET_USER','SET_TOKEN','SET_ACCESS_TOKEN','LOG_OUT'
            ]),
            getLogOut(){
                this.LOG_OUT();
                window.location = '/'
            }
        }
    }
</script>
