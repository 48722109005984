<template>
  <div>
    <notifications group="foo"></notifications>
    <v-app class="bg-spring">
      <vue-progress-bar></vue-progress-bar>
      <router-view name="outer"></router-view>
    </v-app>
  </div>

</template>

<script>


export default {
  name: 'App',
  data: () => ({
    //
  }),
};
</script>
<style>
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type=number] {
      -moz-appearance:textfield;
  }
  .vue-notification {
    padding: 15px;
    margin: 5px 5px 5px !important;
    border-radius: 5px;
    font-size: 12px;
    color: #ffffff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-left: 10px solid #187FE7 !important;
    background: #44A4FC;

  }
  .vue-notification .notification-title{
    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }
  .vue-notification .notification-content{
    font-size: 13px;
    font-family: "Roboto", sans-serif;
  }
  .vue-notification-wrapper .warning {
    background: #ffb648 !important;

    border-left-color: #f48a06 !important;
  }

  .vue-notification-wrapper .error {
    background: #E54D42 !important;
    border-left-color: #B82E24 !important;
  }

  .vue-notification-wrapper .success {
    background: #68CD86 !important;

    border-left-color: #42A85F !important;
  }

  .hint{
    font-size: 12px;color: #aeaeae;
  }
  .v-application p{
    margin-bottom: 0px !important;
  }
  .v-toolbar__content, .v-toolbar__extension{
    padding: 0px 16px !important;
  }

  .v-btn{
    text-transform: capitalize !important;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .bg-spring {
    color: #fff;
    background: -webkit-linear-gradient(110deg, #fffff7 40vw, rgba(0, 0, 0, 0) 40vw), -webkit-radial-gradient(farthest-corner at 0% 0%, #f7f9ff 75%, #fcfffc 70%)  !important;
    background: -o-linear-gradient(110deg, #fffff7 40vw, rgba(0, 0, 0, 0) 40vw), -o-radial-gradient(farthest-corner at 0% 0%, #f7f9ff 75%, #fcfffc 70%)  !important;
    background: -moz-linear-gradient(110deg, #fffff7 40vw, rgba(0, 0, 0, 0) 40vw), -moz-radial-gradient(farthest-corner at 0% 0%, #f7f9ff 75%, #fcfffc 70%)  !important;
    background: linear-gradient(110deg, #fffff7 40vw, rgba(0, 0, 0, 0) 40vw), radial-gradient(farthest-corner at 0% 0%, #f7f9ff 75%, #fcfffc 70%) !important;
  }
  .bg-purple {
    color: #fff;
    background: -webkit-linear-gradient(110deg, #fffff7 40vw, rgba(0, 0, 0, 0) 40vw), -webkit-radial-gradient(farthest-corner at 0% 0%, #f7f9ff 75%, #f7f9ff 70%);
    background: -o-linear-gradient(110deg, #fffff7 40vw, rgba(0, 0, 0, 0) 40vw), -o-radial-gradient(farthest-corner at 0% 0%, #f7f9ff 75%, #fcfffc 70%);
    background: -moz-linear-gradient(110deg, #fffff7 40vw, rgba(0, 0, 0, 0) 40vw), -moz-radial-gradient(farthest-corner at 0% 0%, #f7f9ff 75%, #f7f9ff 70%);
    background: linear-gradient(110deg, #fffff7 40vw, rgba(0, 0, 0, 0) 40vw), radial-gradient(farthest-corner at 0% 0%, #f7f9ff 75%, #f7f9ff 70%);
  }
  .text--white {
    color:#ffffff
  }
  .text--header{
    font-size: 16px;
    font-weight: normal;
  }

  .test--item .v-expansion-panel-header__icon i{
    color: #ffffff !important;
  }

  @media only screen and (max-width: 600px) {
    .small-device {
      width: 100% !important;
    }
  }
  .v-toolbar.v-toolbar--absolute{
    z-index: 100 !important;
  }
  .v-navigation-drawer--temporary{
    z-index: 200 !important;
  }

</style>
