import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    static: {
      AppTitle: process.env.VUE_APP_APPTITLE,
      AppSubtitle: "",
      Instance: process.env.VUE_APP_Instance,
      colors: {
        primary: "#607d8b",
        secondary: "#FFCDD2",
        accent: "#3F51B5",
      },
    },
    dynamic: {
      activeView: "",
      activePage: null,
      user: JSON.parse(sessionStorage.getItem("user")),
      token: sessionStorage.getItem("token"),
      mobile: sessionStorage.getItem("mobile"),
      sigInToken: sessionStorage.getItem("sign_in_token"),
    },
  },
  mutations: {
    SET_ACTIVE_VIEW: (state, activeView) => {
      state.dynamic.activeView = activeView;
    },
    SET_ACTIVE_PAGE: (state, activePage) => {
      state.dynamic.activePage = activePage;
    },
    SET_USER: (state, user) => {
      sessionStorage.setItem("user", JSON.stringify(user));
      state.dynamic.user = JSON.parse(sessionStorage.getItem("user"));
    },
    SET_MOBILE: (state, mobile, token) => {
      state.dynamic.mobile = mobile;
      sessionStorage.setItem("mobile", mobile);
    },
    SET_SIGN_IN_TOKEN: (state, token) => {
      state.dynamic.sigInToken = token;
      sessionStorage.setItem("sign_in_token", token);
    },
    SET_TOKEN: (state, token) => {
      sessionStorage.setItem("token", token);
      state.dynamic.token = sessionStorage.getItem("token");
    },
    LOG_OUT: (state) => {
      sessionStorage.setItem("token", "");
      sessionStorage.setItem("token", "");
      state.dynamic.token = null;
      state.dynamic.user = null;
    },
  },
  actions: {},
});
