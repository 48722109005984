<template>
    <v-container>
        <v-row justify="center">
            <v-col md="6" lg="4" sm="12" xs="12" style="margin-top: 10vh;">
                <v-card class="elevation-6">
                    <v-card class="primary" dark style="padding: 15px;border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;text-align: center;position: relative" >
                        <v-btn text @click="$router.go(-1)" icon color="white" style="position: absolute;top: 10px;left: 10px">
                            <v-icon>mdi-keyboard-backspace</v-icon>
                        </v-btn>
                        <v-avatar size="50" color="primary" >
                            <v-icon style="font-size: 60px" dark>mdi-account-circle</v-icon>
                        </v-avatar>
                        <h2 class="font-weight-light text-md-center text-lg-center form-title" style="margin-top: 20px">Confirm OTP</h2>
                        <h2 class="font-weight-regular text-md-center text-lg-center body-1">Signin to your {{static.AppTitle}} account</h2>
                    </v-card>
                    <v-card-text>
                        <v-form autocomplete="off" ref="form" v-model="valid" lazy-validation  @submit.prevent= "validateNewForm">
                            <v-text-field :persistent-hint="true" v-model="user.otp" :rules="rules.otp" hint="Input OTP send to your registered mobile no." label="OTP" type="number">
                                <template slot="prepend">
                                    <v-icon>mdi-security</v-icon>
                                </template>
                            </v-text-field>
                            <v-btn style="margin-top: 20px;margin-bottom: 0px" block  type="button" :loading="submit"
                                   :disabled="submit" @click="validateNewForm" color="primary"><v-icon>mdi-arrow-right</v-icon> Sign In
                                <template v-slot:loader>
                                            <span class="custom-loader">
                                              <v-icon light>mdi-cached</v-icon>
                                            </span>
                                </template>
                            </v-btn>
                            <v-row style="margin-top:20px;margin-right: 5px" >
                                <v-spacer></v-spacer>
                            <v-btn text v-if="resendable" @click="resendOTP" color="primary">Resent OTP</v-btn>
                            </v-row>
                            <template  v-if="!resendable">
                                <v-row style="margin-top:20px;margin-right: 5px" >
                                    <v-spacer></v-spacer>
                                    <vue-countdown-timer
                                            style="float: right;font-weight: bold"
                                            @end_callback="hideTimer"
                                            :start-time="resendTimerStart"
                                            :end-time="resendTimerEnd"
                                            :interval="1000"
                                            label-position="begin"
                                            :seconds-txt="'seconds'">
                                        <template slot="countdown" slot-scope="scope">
                                            <span style="text-align: right">Resend OTP in : {{scope.props.seconds}} {{scope.props.secondsTxt}}</span>
                                        </template>

                                    </vue-countdown-timer>
                                </v-row>


                            </template>

                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import axios from 'axios'
    import {mapState,mapMutations} from 'vuex'
    var moment = require('moment');
    export default {
        data(){
            return{
                user:{
                    telephone:null,
                    otp:"",
                    token:null,

                },
                resendable:false,
                resendTimerStart:null,
                resendTimerEnd:null,
                response:null,
                submit:false,
                valid:true,
                rules:{
                    otp:[
                        v => !!v || 'OTP no. is required',
                        v => v.length===6 || 'Enter a valid OTP.',
                    ],
                },
            }
        },
        mounted(){
            this.showTimer()
        },
        computed:{
            ...mapState([
                'static','dynamic'
            ]),

        },
        methods:{
            ...mapMutations([
                'SET_ACTIVE_VIEW','SET_USER','SET_TOKEN','SET_ACCESS_TOKEN','SET_SIGN_IN_TOKEN','LOG_OUT'
            ]),
            hideTimer(){
              this.resendable=true;
            },
            calculateTimerTimes(){
                var CurrentDateTime = moment();
                this.resendTimerStart=CurrentDateTime.unix();
                this.resendTimerEnd=CurrentDateTime.add(1, 'minutes').unix();
            },
            showTimer(){
                this.calculateTimerTimes();
                this.resendable=false;
            },
            confirmOTP(){
                this.response=null
                this.$Progress.start();
                this.submit=true
                this.user.telephone=this.dynamic.mobile;
                this.user.token=this.dynamic.sigInToken;
                let basic=btoa(this.OAUTH_CLIENT_ID+":"+this.OAUTH_CLIENT_SECRET);
                let config={
                    headers:{
                        "Authorization":"Basic "+basic,
                        "Content-Type":"application/x-www-form-urlencoded",
                        "Verification-Token":this.dynamic.sigInToken
                    }
                }
                const params = new URLSearchParams();
                params.append('username', this.dynamic.mobile);
                params.append('password', this.user.otp);
                params.append('client_id', this.OAUTH_CLIENT_ID);
                params.append('scope', this.OAUTH_SCOPE);
                params.append('client_secret', this.OAUTH_CLIENT_SECRET);
                params.append('grant_type',this.OAUTH_GRANT_TYPE);

                console.log("data",params);
                this.$http.post(this.SECURITY+'/auth/oauth/token',params,config)
                    .then(response => {
                        console.log("Auth response",response.data);

                            this.$Progress.finish();
                            let token=response.data.access_token;
                            this.SET_TOKEN(token)
                            this.getUserDetails();
                            /*this.$router.replace('/authorized/test/results',null)*/
                            this.submit=false
                    })
                    .catch(e => {
                        console.log("error",e)
                        this.submit=false
                        this.$Progress.fail()
                        this.response=e.response.data
                        this.$notify({
                            group: 'foo',
                            title: this.response.error,
                            type:'error',
                            text: this.response.error_description
                        });
                    })

            },
            resendOTP(){
                this.$Progress.start();
                let data={telephone : this.dynamic.mobile, operation : "Login", role : "Customer", token : this.dynamic.sigInToken
                }
                let config={
                    headers:{
                        "Verification-Token":this.dynamic.sigInToken
                    }
                }
                this.$http.post(this.SECURITY+'/auth/otp/resend',data,config)
                    .then(response => {
                            this.$Progress.finish();
                            let body=JSON.parse(response.data.body);
                            console.log("body",body)
                            this.SET_SIGN_IN_TOKEN(body.token)
                            this.$notify({
                                group: 'foo',
                                title: "Success",
                                type:'success',
                                text: "OTP has been resent successfully."
                            });

                        this.showTimer();
                    })
                    .catch(e => {
                        this.LOG_OUT()
                        console.log("error",e)
                        this.submit=false
                        this.$Progress.fail()
                        this.response=e.response.data
                        this.$notify({
                            group: 'foo',
                            title: this.response.error,
                            type:'error',
                            text: this.response.error_description
                        });
                    })

            },
            getUserDetails(){
                this.$Progress.start();
                let config={
                    headers:{
                        "Authorization":"Bearer "+this.dynamic.token
                    }
                }
                this.$http.get(this.SECURITY+'/auth/user',config)
                    .then(response => {
                        this.$Progress.finish();
                        let user=response.data;
                        this.SET_USER(user)
                        this.$router.replace('/authorized/test/results',null)

                    })
                    .catch(e => {
                        console.log("error",e)
                        this.submit=false
                        this.$Progress.fail()
                        this.response=e.response.data
                        this.$notify({
                            group: 'foo',
                            title: this.response.status,
                            type:'error',
                            text: this.response.message
                        });
                    })
            },
            validateNewForm(){
                if (this.$refs.form.validate()) {
                    this.confirmOTP()
                }
            },
        }
    };
</script>
<style>
    .v-text-field .v-input__prepend-inner{
        padding-top: 5px;
    }
    .or{

        width: 100%;
        text-align: center;
        border-bottom: 1px solid #d1d1d1;
        line-height: 0.1em;
        margin: 30px 0 20px;
    }
    .or span {
        background:#fff;
        padding:0 10px;
    }
</style>
