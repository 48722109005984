import Vue from 'vue'
import Router from 'vue-router'
import Public from './views/wrapper/Public'
import Authorized from './views/wrapper/Authorized'
import Login from './views/public/Login'
import OTPConfirm from './views/public/OTPConfirm'
import TestReports from './views/authorized/TestReport'
import PageNotFound from './views/wrapper/PageNotFound'
Vue.use(Router)
import store from './store'
const router = new Router({
  mode: "hash",
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: "",
      components: {
        outer: Public,
      },
      children: [
        {
          path: "",
          components: {
            public: Login,
          },
        },
        {
          path: "/authentication",
          components: {
            public: Login,
          },
        },
        {
          path: "/authentication/confirm/:id",
          components: {
            public: OTPConfirm,
          },
        },
      ],
    },
    {
      path: "/authorized",
      components: {
        outer: Authorized,
      },
      children: [
        {
          path: "test/results",
          components: {
            authorized: TestReports,
          },
        },
      ],
    },
    {
      path: "*",
      components: {
        outer: PageNotFound,
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
    console.log("c");
    let token=store.state.dynamic.token;
    console.log(token);
    /*if (to.path.startsWith('/authorized')){
        if(token==null){
            window.location='/authentication'
        }else{
            next();
        }
    }*/
    if (to.path.startsWith('/authentication')){
        if(token){
            window.location='/authorized'
        }else{
            next();
        }
    }
    next();
});
export default router;
